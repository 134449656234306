import React, { useState, useEffect, useRef } from "react";
import { getData } from "../Services/AccessAPI";
import Table from "../Utilities/Table/Table";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import "./Keywords.css";
import { Tooltip } from "@mui/material";

const Keywords = ({ searchTerm }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [facultyData, setFacultyData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [isViewClicked, setIsViewClicked] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [viewData, setViewData] = useState({
    facultyBigramModel: [],
    orgBigramModel: [],
  });
  const [facultyDataCsv, setFacultyDataCsv] = useState([['Faculty Name', 'ASU Search URL', 'Topic Count']]);
  const [orgDataCsv, setOrgDataCsv] = useState([['Organization Name', 'Org URL', 'Topic Count']]);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getData(
          `api/Home/getKeywordSearchResults_Moonshot?searchText=${searchTerm}&geoData=${sessionStorage.getItem("userLocation")}`
        );
        setSearchResults(data.message);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    if (searchTerm !== "") {
      setIsLoading(true);
      fetchData();
    }
  }, [searchTerm]);

  useEffect(() => {
    setFacultyData(viewData.facultyBigramModel);
    setOrgData(viewData.orgBigramModel);

    let facultyCsv = [['Faculty Name', 'ASU Search URL', 'Topic Count']];
    let orgCsv = [['Organization Name', 'Org URL', 'Topic Count']];

    viewData.facultyBigramModel.forEach((item) => {
      facultyCsv.push([item.facultyName, item.facultyISearchLink, item.facultyBigramWeight])
    });
    viewData.orgBigramModel.forEach((item) => {
      orgCsv.push([item.organizationName, item.orgURL, item.orgBigramWeight])
    });

    setFacultyDataCsv(facultyCsv);
    setOrgDataCsv(orgCsv);

  }, [viewData]);

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, []);

  const handleViewButtonClick = async (suggestionWord) => {
    setIsLoading(true);
    try {
      const data = await getData(
        `api/Home/getOrgFacultyListForKeyword_Moonshot?searchText=${suggestionWord}`
      );
      setViewData(data.message);
      setIsViewClicked(true);
      setSelectedKeyword(suggestionWord);
      setShowExportOptions(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const toggleDropdown = () => {
    setShowExportOptions(!showExportOptions);
  };

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowExportOptions(false);
    }
  };

  // Define table columns and data
  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div className="custom-header">
            <span>Search Results for Topic</span>
            <span className="count-badge">{searchResults.length}</span>
          </div>
        ),
        accessor: "suggestionWords",
        sortType: "alphanumeric",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => {
          const renderExportLink = () => {
            if (selectedKeyword === row.original.suggestionWords) {
              return (
                <>
                  <div className="dropdown" ref={dropdownRef}>
                    <Tooltip title="Export CSV" placement="bottom-start">
                      <span
                        className={`dropdown-toggle ${
                          showExportOptions ? "active" : ""
                        }`}
                        onClick={toggleDropdown}
                      >
                        <FontAwesomeIcon icon={faFileCsv} />
                      </span>
                    </Tooltip>
                    {showExportOptions && (
                      <ul className="dropdown-menu" style={{ left: "auto" }}>
                        <CSVLink
                          data={facultyDataCsv}
                          filename={`${selectedKeyword}_faculty_matches.csv`}
                        >
                          <li onClick={toggleDropdown}>Export Faculty data</li>
                        </CSVLink>

                        <CSVLink
                          data={orgDataCsv}
                          filename={`${selectedKeyword}_org_matches.csv`}
                        >
                          <li onClick={toggleDropdown}>Export Org data</li>
                        </CSVLink>
                      </ul>
                    )}
                  </div>
                </>
              );
            }
            return null;
          };
      
          return (
            <>
              <a
                className="view-button"
                tabIndex="0"
                onClick={() => handleViewButtonClick(row.original.suggestionWords)}
              >
                View
              </a>
              {renderExportLink()}
            </>
          );
        },
      }
      
    ],
    [searchResults.length, selectedKeyword, showExportOptions]
  );

  // Define table columns and data for faculty
  const facultyColumns = React.useMemo(
    () => [
      {
        Header: "Faculty Name",
        accessor: "facultyName",
        Cell: ({ row }) => (
          <a
            href={row.original.facultyISearchLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.original.facultyName}
          </a>
        ),
        sortType: "alphanumeric",
      },
      {
        Header: "Topic Count",
        accessor: "facultyBigramWeight",
        sortType: (rowA, rowB, columnId) => {
          const valueA = parseFloat(rowA.values[columnId]);
          const valueB = parseFloat(rowB.values[columnId]);
          return valueA - valueB;
        },
      },
    ],
    []
  );

  // Define table columns and data for organizations
  const orgColumns = React.useMemo(
    () => [
      {
        Header: "Organization Name",
        accessor: "organizationName",
        Cell: ({ row }) => (
          <a
            href={
              row.original.orgURL.startsWith("http")
                ? row.original.orgURL
                : `http://${row.original.orgURL}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.original.organizationName}
          </a>
        ),
        sortType: "alphanumeric",
      },
      {
        Header: "Topic Count",
        accessor: "orgBigramWeight",
        sortType: (rowA, rowB, columnId) => {
          const valueA = parseFloat(rowA.values[columnId]);
          const valueB = parseFloat(rowB.values[columnId]);
          return valueA - valueB;
        },
      },
    ],
    []
  );

  return (
    <div className="keywords-container">
      <h2 className="container-title">
        {selectedKeyword ? (
          <>
            Showing faculty and organization matches for the keyword:{" "}
            <span className="black-on-gold">{selectedKeyword}</span>
          </>
        ) : (
          "Keyword Lookup"
        )}
      </h2>

      <div className="table-container">
        {isLoading && <LoadingSpinner />}
        <div
          className={`keyword-search-data ${
            searchResults.length > 0 ? "" : "full-width"
          }`}
        >
          {searchResults.length > 0 ? (
            <>
              <Table
                columns={columns}
                data={searchResults}
                disableFilterOptionsForIds={["action"]}
              />
            </>
          ) : (
            !isLoading && (
              <p className="no-data-text">
                Search for topics in the search box above
              </p>
            )
          )}
        </div>
        {searchResults.length > 0 && (
          <div className="keyword-view-data">
            {!isViewClicked ? (
              <p className="no-data-text">
                Click on the view button to get faculty and organization matches
              </p>
            ) : (
              <div className="keyword-side-panel">
                <div className="keyword-faculty-data">
                  {viewData.facultyBigramModel.length > 0 ? (
                    <Table columns={facultyColumns} data={facultyData} />
                  ) : (
                    <p className="no-data-text">No faculty matches found</p>
                  )}
                </div>
                <div className="keyword-org-data">
                  {viewData.orgBigramModel.length > 0 ? (
                    <Table columns={orgColumns} data={orgData} />
                  ) : (
                    <p className="no-data-text">
                      No organization matches found
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Keywords;
