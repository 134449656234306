import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
// import { Layout } from "./components/Layout";
import Login from "./components/Auth/Login";
import Logout from "./components/Auth/Logout";
import SessionManager from "./components/Auth/SessionManager";
import Dashboard from "./components/Dashboard/Dashboard";
import NavMenu from "./components/Navigation/NavMenu";
import Sidebar from "./components/Navigation/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./custom.css";
import Search from "./components/Search/Search";
import MoonshotSearch from "./components/MoonshotSearch/Search";
import Tags from "./components/Tags/Tags";
import Uploads from "./components/Uploads/Uploads";
import About from "./components/About/About";
import Register from "./components/Auth/Register";
import People from "./components/People/People";
import AIR from "./components/AIR/AIR";

const App = () => {
  const isLoggedIn = SessionManager.getToken();

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
      />
      <Router>
        {isLoggedIn ? (
          <>
            <NavMenu />
            <div className="layout">
              <Sidebar />
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/search" component={Search} />
              <Route path="/moonshot" component={MoonshotSearch} />
              <Route path="/aiirsearch" component={AIR} />
              <Route path="/tags" component={Tags} />
              <Route path="/upload" component={Uploads} />
              <Route path="/about" component={About} />
              <Route path="/people" component={People} />
              <Route path="/logout" component={Logout} />
            </div>
          </>
        ) : (
          <>
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="*">
              <Redirect to="/login" />
            </Route>
          </>
        )}
      </Router>
    </div>
  );
};

export default App;
