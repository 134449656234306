const SessionManager = {
  getToken: () => {
    const token = sessionStorage.getItem("token");
    return token || null;
  },

  setUserSession: (token, userId, userName) => {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("userId", userId);
    sessionStorage.setItem("userName", userName);
  },

  removeUserSession: () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userName");
  },
};

export default SessionManager;
