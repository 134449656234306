import React, { useState, useEffect } from "react";
import "./AIR.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import TableWithAccordian from "./TableWithAccordian";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { AIR_API_BASE_URL } from "../Services/Settings";
import { toast } from "react-toastify";

const AIR = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [facultyData, setFacultyData] = useState([]);
  const [orgData, setOrgData] = useState([]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const updateSearchTerm = (term) => {
    setSearchTerm(term);
  };
  const clearSearch = () => {
    setSearchTerm("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Perform search logic with the search term
    console.log("Searching for:", searchTerm);

    try {
      const response = await fetch(
        AIR_API_BASE_URL + `search?input_text=${searchTerm}`
      );
      const data = await response.json();

      setFacultyData(data.faculty);
      setOrgData(data.organization);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
      setIsLoading(false);
    }
  };

  // Define table columns and data for faculty
  const facultyColumns = React.useMemo(
    () => [
      {
        Header: "Faculty Name",
        accessor: (value) => value["FacultyName"],
        Cell: ({ row }) => (
          <a href={row.original.URL} target="_blank" rel="noopener noreferrer">
            {row.original.FacultyName}
          </a>
        ),
        sortType: "alphanumeric",
      },
    ],
    []
  );

  // Define table columns and data for organizations
  const orgColumns = React.useMemo(
    () => [
      {
        Header: "Organization Name",
        accessor: "OrgName",
        Cell: ({ row }) => (
          <a
            href={
              row.original.URL.startsWith("http")
                ? row.original.URL
                : `http://${row.original.URL}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.original.OrgName}
          </a>
        ),
        sortType: "alphanumeric",
      },
    ],
    []
  );

  return (
    <div className="air-container">
      <div className="main-container">
        <div className="search-header">
          <div className="search-title">
            <div className="background-image" />
            <h1 className="section-title">
              <span className="black-on-gold">
                Find Your Potential Match with AIIR
              </span>
            </h1>
            <p>
              Leverage our cutting edge Artificial-Intelligence Information
              Retrieval (AIIR) System, driven by Semantic Analysis, to
              effortlessly identify potential faculty and organizational matches
              closely aligned with your search text. Uncover the most relevant
              connections with precision and efficiency through our advanced
              technology.
            </p>
          </div>
        </div>
        <div className="search-box-container">
          <input
            type="text"
            placeholder="Enter your search text"
            className="search-box"
            value={searchTerm}
            onChange={handleSearch}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />
          {searchTerm && (
            <Tooltip title="Clear Search" placement="bottom-start">
              <span className="clear-icon" onClick={clearSearch}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </Tooltip>
          )}
          <button
            type="submit"
            className="search-button"
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon={faSearch} />
            Search
          </button>
        </div>

        <div className="table-container">
          {isLoading && <LoadingSpinner />}
          <div className="air-search-data">
            {(searchTerm !== "" && (orgData.length > 0 || facultyData.length > 0)) ? (
              <>
                <div className="keyword-faculty-data">
                  {facultyData.length > 0 ? (
                    <TableWithAccordian
                      columns={facultyColumns}
                      data={facultyData}
                    />
                  ) : (
                    <p className="no-data-text">No faculty matches found</p>
                  )}
                </div>
                <div className="keyword-org-data">
                  {orgData.length > 0 ? (
                    <TableWithAccordian columns={orgColumns} data={orgData} />
                  ) : (
                    <p className="no-data-text">
                      No organization matches found
                    </p>
                  )}
                </div>
              </>
            ) : (
              !isLoading && (
                <p className="no-data-text">
                  Search for topics in the search box above
                </p>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIR;
