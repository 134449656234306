import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLandmark,
  faArrowTrendUp,
  faUsers,
  faRectangleList,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Map from "./Map";
import PopularTags from "./PopularTags";
import SearchesByMonth from "./SearchesByMonth";
import InterestingFinds from "./InterestingFinds";
import WordCloudComponent from "./WordCloud";
import ProjectTimeline from "./ProjectTimeline";
import { getData } from "../Services/AccessAPI";
import "./Dashboard.css";

const Dashboard = () => {
  const [cardStats, setCardStats] = useState({
    organizationCount: 0,
    facultyCount: 0,
    edgeCount: 0,
    searchCount: 0,
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Check if geolocation is available in the user's browser
    if ("geolocation" in navigator) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = `${latitude},${longitude}`;
          // Set the user's location in session storage
          sessionStorage.setItem("userLocation", userLocation);
        },
        (error) => {
          console.error("Error fetching user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);
  
  const fetchData = async () => {
    try {
      const data = await getData("api/Home/getDashboardCardStats");
      if (data) {
        setCardStats(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="dashboard-container">
      {/* <div className="background-image" /> */}
      <div className="dashboard-welcome">
        <div className="background-image" />
        <h1 className="section-title">
          Welcome to{" "}
          <span className="black-on-gold">Knowledge Alliance Dashboard.</span>
        </h1>
        <p>
          Discover collaborative opportunities between university researchers
          and community organizations using the powerful Knowledge Alliance
          Tool. Begin your journey with the{" "}
          <Link to="/search" className="search-link">
            Knowledge Alliance Search
          </Link>
          .
          {/* <br />
          <br />
          Here are some quick stats about Knowledge Alliance Tool: */}
        </p>
      </div>
      <div className="dashboard-content">
        <div className="dashboard-cards-container">
          <div className="dashboard-card">
            <div className="card-upper-pane">
              <div className="card-data">
                <span>{cardStats.organizationCount}</span>
                <p>Total Organizations</p>
              </div>
              <div className="card-icon">
                <FontAwesomeIcon icon={faLandmark} />
              </div>
            </div>
            <div className="card-lower-pane">
              {/* <span className="change-text">% change in 30 days</span>
              <span className="change-value">
                <span>#5</span>
                <FontAwesomeIcon icon={faArrowTrendUp} />
              </span> */}
            </div>
          </div>
          <div className="dashboard-card">
            <div className="card-upper-pane">
              <div className="card-data">
                <span>{cardStats.facultyCount}</span>
                <p>Faculty/Researchers</p>
              </div>
              <div className="card-icon">
                <FontAwesomeIcon icon={faUsers} />
              </div>
            </div>
            <div className="card-lower-pane">
              {/* <span className="change-text">% change in 30 days</span>
              <span className="change-value">
                <span>#5</span>
                <FontAwesomeIcon icon={faArrowTrendUp} />
              </span> */}
            </div>
          </div>
          <div className="dashboard-card">
            <div className="card-upper-pane">
              <div className="card-data">
                <span>{cardStats.edgeCount}</span>
                <p>Categories</p>
              </div>
              <div className="card-icon">
                <FontAwesomeIcon icon={faRectangleList} />
              </div>
            </div>
            <div className="card-lower-pane">
              {/* <span className="change-text">% change in 30 days</span>
              <span className="change-value">
                <span>#5</span>
                <FontAwesomeIcon icon={faArrowTrendUp} />
              </span> */}
            </div>
          </div>
          <div className="dashboard-card">
            <div className="card-upper-pane">
              <div className="card-data">
                <span>{cardStats.searchCount}</span>
                <p>All Searches</p>
              </div>
              <div className="card-icon">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </div>
            </div>
            <div className="card-lower-pane">
              {/* <span className="change-text">% change in 30 days</span>
              <span className="change-value">
                <span>#5</span>
                <FontAwesomeIcon icon={faArrowTrendUp} />
              </span> */}
            </div>
          </div>
        </div>
        <div className="data-container-row-1">
          <div className="popular-tags-container">
            <PopularTags />
          </div>
          <div className="map-container">
            <h2 className="container-title">Searches by Geography</h2>
            <Map />
          </div>
        </div>
        <div className="data-container-row-2">
          <div className="searches-by-month">
            <SearchesByMonth />
          </div>
          <div className="interesting-finds">
            <InterestingFinds />
          </div>
        </div>
        <div className="data-container-row-3">
          <div className="word-cloud">
            <h2 className="container-title">Most Searched</h2>
            <WordCloudComponent />
          </div>
          <div className="project-timeline">
            <h2 className="container-title">Project Timeline</h2>
            <ProjectTimeline />
          </div>
        </div>
        {/* <Map data={dataset} /> */}
      </div>
    </div>
  );
};

export default Dashboard;
