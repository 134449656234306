import React from "react";
import { Navbar, NavbarBrand, Nav, NavItem } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import SessionManager from "../Auth/SessionManager";
import asu_ker_logo from "../../images/asu_ker_logo.png";
import "./NavMenu.css";

const NavMenu = () => {
  // const history = useHistory();
  const userName = sessionStorage.getItem("userName") || "User";

  const handleLogout = () => {
    // history.push("/logout");
    SessionManager.removeUserSession();
    window.location.href = "/login";
  };

  return (
    <Navbar className="navbar" light expand="md">
      <NavbarBrand tag={Link} to="/">
        <img src={asu_ker_logo} alt="Logo" className="logo" />
        <span className="nav-title">Knowledge Alliance Tool</span>
      </NavbarBrand>
      <Nav className="nav-items" navbar>
        <NavItem>
          <span className="user-greeting">Hello {userName}!</span>
          <span className="logout-button" onClick={handleLogout}>Logout</span>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default NavMenu;
