import React, { useState, useEffect } from "react";
import "./Search.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

import SharedTopics from "./SharedTopics";
import Expertise from "./Expertise";
import Keywords from "./Keywords";
import { getDataFromFastAPI } from "../Services/AccessAPI";

const MoonshotSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("sharedTopics"); // Set default active tab
  const [searchTerms, setSearchTerms] = useState({
    sharedTopics: "",
    expertise: "",
    keywords: "",
  });
  const [keywordSuggestions, setKeywordSuggestions] = useState([]);
  const [isSuggestionClicked, setIsSuggestionClicked] = useState(false);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const updateSearchTerm = (term) => {
    setSearchTerm(term);
  };
  const clearSearch = () => {
    setSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [activeTab]: "",
    }));
  };

  useEffect(() => {
    setSearchTerm(searchTerms[activeTab]);
  }, [activeTab, searchTerms]);

  useEffect(() => {
    if (isSuggestionClicked) {
      updateKeywordSuggestions();
      setIsSuggestionClicked(false);
    }
  }, [searchTerm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform search logic with the search term
    console.log("Searching for:", searchTerm);
    setSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [activeTab]: searchTerm,
    }));

    if(activeTab === "expertise"){
      updateKeywordSuggestions();
    }
  };

  const updateKeywordSuggestions = async () => {
    try{
      const data = await getDataFromFastAPI(
        `get_recommendations/?query_word=${searchTerm}`
      );
      setKeywordSuggestions(data.recommendations);
    }
    catch(error){
      console.error("Error fetching recommendations: ", error);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleExpertiseKeywordChange = async (keyword) => {
    setSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [activeTab]: keyword,
    }));
    setIsSuggestionClicked(true);
  };

  const getPlaceholderText = () => {
    switch (activeTab) {
      case "sharedTopics":
        return "Search for organizations, faculty, or enter shared interest between them";
      case "expertise":
        return "Search faculty by expertise area";
      case "keywords":
        return "Search by keywords (single or multiple)";
      default:
        return "Search...";
    }
  };

  const renderComponent = () => {
    // debugger;
    switch (activeTab) {
      case "sharedTopics":
        return (
          <SharedTopics
            searchTerm={searchTerms.sharedTopics}
            updateSearchTerm={updateSearchTerm}
          />
        );
      case "expertise":
        return <Expertise searchTerm={searchTerms.expertise} />;
      case "keywords":
        return <Keywords searchTerm={searchTerms.keywords} />;
      default:
        return null;
    }
  };

  return (
    <div className="search-container">
      <div className="main-container">
        <div className="search-header">
          <div className="search-title">
            <div className="background-image" />
            <h1 className="section-title">
              <span className="black-on-gold">Knowledge Alliance Search - Moonshot</span>
            </h1>
            <p>
              Navigate between the tabs below to search for the organization and
              faculty matches based on shared topics, keywords and expertise
              areas.
            </p>
          </div>
        </div>
        <div className="search-box-container">
          <input
            type="text"
            placeholder={getPlaceholderText()}
            className="search-box"
            value={searchTerm}
            onChange={handleSearch}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />
          {searchTerm && (
            <Tooltip title="Clear Search" placement="bottom-start">
              <span className="clear-icon" onClick={clearSearch}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </Tooltip>
          )}
          <button
            type="submit"
            className="search-button"
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon={faSearch} />
            Search
          </button>
        </div>
        {activeTab === "expertise" &&
          searchTerm &&
          keywordSuggestions.length > 0 && (
            <div className="keyword-suggestions">
              <span className="suggestions-title">
                Related Expertise Areas:
              </span>
              <div className="keyword-suggestions-container">
                {keywordSuggestions?.map((keyword, index) => (
                  <div
                    className="keyword"
                    key={index}
                    onClick={() => handleExpertiseKeywordChange(keyword)}
                  >
                    {keyword}
                  </div>
                ))}
              </div>
            </div>
          )}

        <div className="tabs-container">
          <div
            className={`tab ${
              activeTab === "sharedTopics" ? "active-tab" : ""
            }`}
            onClick={() => handleTabChange("sharedTopics")}
          >
            Shared Topics
          </div>
          <div
            className={`tab ${activeTab === "keywords" ? "active-tab" : ""}`}
            onClick={() => handleTabChange("keywords")}
          >
            Keywords
          </div>
          <div
            className={`tab ${activeTab === "expertise" ? "active-tab" : ""}`}
            onClick={() => handleTabChange("expertise")}
          >
            Expertise
          </div>
        </div>
        <div className="data-container">{renderComponent()}</div>
      </div>
    </div>
  );
};

export default MoonshotSearch;
