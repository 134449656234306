import React, { useState, useEffect, useRef } from "react";
import { getData, postData } from "../Services/AccessAPI";
import Table from "../Utilities/Table/Table";
import { WithContext as ReactTags } from "react-tag-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Expertise.css";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faShare, faShareAltSquare, faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Tooltip } from "@mui/material";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Expertise = ({ searchTerm }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [tags, setTags] = useState({});
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [facultyData, setFacultyData] = useState({});
  const [accordionState, setAccordionState] = useState({
    biography: false,
    research: false,
    publicWork: false,
  });
  const facultyInfoRef = useRef();
  const facultyCitationRef = useRef();

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const data = await getData(
          `api/Home/getFacultyListByExpertiseArea?searchText=${searchTerm}&geoData=${sessionStorage.getItem("userLocation")}`
        );
        // const data = await response.json();
        if (isMounted) {
          setSearchResults(data.message);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    if (searchTerm !== "") {
      setIsLoading(true);
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [searchTerm]);

  useEffect(() => {
    setTags((prevTags) => {
      const updatedTags = { ...prevTags };
      searchResults.forEach((faculty) => {
        const { asuStaffId, facultyTagList } = faculty;
        const selectedTags = facultyTagList
          .filter((tag) => tag.selected)
          .map((tag, index) => ({
            id: (index + 1).toString(),
            name: tag.tagLabel,
            tagId: tag.tagId,
          }));

        updatedTags[asuStaffId] = selectedTags;
      });

      return updatedTags;
    });
  }, [searchResults]);

  useEffect(() => {
    let isMounted = true;

    const fetchTagSuggestions = async () => {
      try {
        const data = await getData(`api/Tag/getTags`);
        // const data = await response.json();
        const suggestedTags = data.message.map((tag, index) => ({
          id: (index + 1).toString(),
          name: tag.text,
        }));
        if (isMounted) {
          setSuggestedTags(suggestedTags);
        }
      } catch (error) {
        console.error("Error fetching tag suggestions:", error);
      }
    };

    fetchTagSuggestions();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleViewButtonClick = async (staffId) => {
    setIsLoading(true);
    try {
      const data = await getData(
        `api/Home/getFacultyDetailedProfile?asuStaffId=${staffId}`
      );
      setFacultyData(data.message);
      setShowData(true);
      setAccordionState({
        biography: false,
        research: false,
        publicWork: false,
      });
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const toggleAccordion = (accordionName) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [accordionName]: !prevState[accordionName],
    }));
  };

  const handleInsertTag = async (tagLabel, facultyId) => {
    const existingTags = tags[facultyId];
    const tagAlreadyExists = existingTags.some((tag) => tag.name === tagLabel);
    // Check if tag already exists or not
    if (tagAlreadyExists) {
      toast.warn("Tag already exists!");
      return;
    }
    // Set limit on number of tags per person
    if (tags[facultyId].length >= 3) {
      toast.warn(
        <div>
          Tag limit exceeded!
          <br /> Cannot add more than 3 tags
        </div>
      );
      return;
    }
    try {
      let tagInfo = {
        tagLabel: tagLabel,
        facultyId: facultyId
      }
      const insertTagForFacultyResponse = await postData(`api/Tag/insertTagForFaculty`, tagInfo);

      if (!insertTagForFacultyResponse.ok) {
        throw new Error("Failed to insert tag.");
      }

      // Update Tags
      setTags((prevTags) => {
        const updatedTags = { ...prevTags };
        if (updatedTags.hasOwnProperty(facultyId)) {
          const existingTags = updatedTags[facultyId];
          const isTagAlreadyExists = existingTags.some(
            (tag) => tag.name === tagLabel
          );
          if (!isTagAlreadyExists) {
            const updatedFacultyTags = [
              ...existingTags,
              {
                id: (existingTags.length + 1).toString(),
                name: tagLabel,
                tagId: tagLabel,
              },
            ];
            updatedTags[facultyId] = updatedFacultyTags;
          }
        } else {
          updatedTags[facultyId] = [{ id: "1", name: tagLabel }];
        }
        return updatedTags;
      });

      // Update suggestions with the newly added tag
      setSuggestedTags((prevTags) => {
        // Check if the tagLabel already exists in suggestedTags
        const isTagAlreadyExists = prevTags.some(
          (tag) => tag.name === tagLabel
        );
        if (isTagAlreadyExists) {
          // If the tagLabel already exists, return the previous suggestedTags without any changes
          return prevTags;
        } else {
          // If the tagLabel doesn't exist, add it to the suggestedTags
          return [
            ...prevTags,
            { id: (prevTags.length + 1).toString(), name: tagLabel },
          ];
        }
      });

      // Toast message
      toast.success("Tag added successfully!");
    } catch (error) {
      toast.error("Failed to add tag!");
      console.error("Error inserting tag:", error);
    }
  };

  const handleRemoveTag = async (i, facultyId) => {
    let tag = tags[facultyId][i];
    // let shiftedFacultyId = facultyId >> 4;
    const formData1 = new FormData();
    formData1.append("facultyId", facultyId);
    formData1.append("tagId", tag.tagId << 4); // Left shifting tag.id as we right shift it again in the backend

    const formData2 = new FormData();
    formData2.append("TagLabel", tag.name);
    formData2.append("facultyId", facultyId);

    try {
      const updateTagItemsForFacultyResponse = await postData(
        `api/Tag/updateTagItemsForFaculty`,
        {
          facultyId: facultyId,
          tagId: tag.tagId << 4,
        }
      );

      if (!updateTagItemsForFacultyResponse.ok || typeof tag.tagId !== "number" || tag.tagId === 0) {


        const removeTagByIdResponse = await postData(
          `api/Tag/removeTagByIdForFaculty`,
          {
            tagLabel: tag.name,
            facultyId: facultyId,
          }
        );

        if (!removeTagByIdResponse.ok) {
          throw new Error("Failed to remove tag.");
        }
      }

      // Update Tags
      setTags((prevTags) => ({
        ...prevTags,
        [facultyId]: prevTags[facultyId].filter((_, index) => index !== i),
      }));

      // Toast message
      toast.success("Tag removed successfully!");
    } catch (error) {
      toast.error("Failed to remove tag!");
      console.error("Error removing tag:", error);
    }
  };

  const facultyColumns = React.useMemo(
    () => [
      {
        // Header: "Name",
        Header: () => (
          <div className="custom-header">
            <span>Name</span>
            <span className="count-badge">{searchResults.length}</span>
          </div>
        ),
        accessor: "facultyName",
        // Enable sorting for this column
        sortType: "alphanumeric",
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => (
          <ReactTags
            tags={tags[row.original.asuStaffId] || []}
            labelField="name"
            suggestions={suggestedTags}
            delimiters={delimiters}
            handleAddition={(tag) => {
              handleInsertTag(tag.name, row.original.asuStaffId);
            }}
            handleDelete={(i) => {
              handleRemoveTag(i, row.original.asuStaffId);
            }}
            placeholder="Select or add a new tag"
            inputFieldPosition="bottom"
            autofocus={false}
            allowDragDrop={false}
            allowDeleteFromEmptyInput={false}
            minQueryLength={1}
            maxTags={4}
            // autocomplete
          />
        ),
      },
      {
        // Header: () => (
        //   <div className="custom-header">
        //     <span className="count-badge">{searchResults.length}</span>
        //   </div>
        // ),
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <a
            className="view-button"
            tabIndex="0"
            onClick={() => handleViewButtonClick(row.original.asuStaffId)}
          >
            View
          </a>
        ),
      },
    ],
    [searchResults.length, tags, suggestedTags]
  );

  const handleDownloadPdf = async () => {
    // Ensure both refs are present
    if (!facultyInfoRef.current || !facultyCitationRef.current) {
      console.error("Missing references.");
      return;
    }

    // Capture both sections as canvas images
    const canvasInfo = await html2canvas(facultyInfoRef.current, {
      useCors: true,
      scale: 1,
    }); // Adjust scale as needed
    const canvasCitation = await html2canvas(facultyCitationRef.current, {
      useCors: true,
      scale: 1,
    }); // Adjust scale as needed

    // Convert canvases to data URLs
    const dataInfo = canvasInfo.toDataURL("image/png");
    const dataCitation = canvasCitation.toDataURL("image/png");

    // Initialize jsPDF
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const padding = 10; // Define padding around images

    // Define a generic function to add images to PDF with padding
    const addImageToPdf = (dataUrl, xPos, yPos) => {
      const imgProps = pdf.getImageProperties(dataUrl);
      const availableWidth = pdfWidth / 2 - 2 * padding; // Half width minus padding for each side
      const scaledHeight = (imgProps.height * availableWidth) / imgProps.width; // Scale height to maintain aspect ratio

      pdf.addImage(
        dataUrl,
        "PNG",
        xPos + padding,
        yPos + padding,
        availableWidth,
        scaledHeight
      );
      return scaledHeight + 2 * padding; // Height of the image including padding
    };

    // Add both images to the PDF, side by side, with padding
    const infoImageHeight = addImageToPdf(dataInfo, 0, 0); // Left half, starting yPos = 10
    const citationImageHeight = addImageToPdf(dataCitation, pdfWidth / 2, 0); // Right half, starting yPos = 10

    // Determine the starting position for the next section of the PDF content
    let yOffset = Math.max(infoImageHeight, citationImageHeight) - 10; // '-10' to reduce the gap btwn images and next heading

    const textData = [
      { heading: "Interest Topics", content: facultyData?.interestedTopics },
      { heading: "Biography", content: facultyData?.bio },
      { heading: "Research", content: facultyData?.research },
      { heading: "Public Work", content: facultyData?.publicWork },
    ];

    // let yOffset = pdfHeight + 10; // Start Y position for text

    textData.forEach(({ heading, content }) => {
      if (content) {
        const paragraphs =
          heading === "Interest Topics"
            ? [content.map((item) => item.interestTopics).join(", ")]
            : content.split("\n").join("\n\n").split("\n");
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        pdf.text(10, yOffset, heading);

        yOffset += 8; // Add some padding below the heading

        paragraphs.forEach((paragraph) => {
          const lines = pdf.splitTextToSize(paragraph, pdfWidth - 20); // Adjust for margins
          const lineHeight = pdf.getLineHeight() / pdf.internal.scaleFactor;
          if (yOffset + lineHeight > pdf.internal.pageSize.getHeight() - 20) {
            pdf.addPage(); // Add a new page if the content exceeds the current page
            yOffset = 10; // Reset Y offset for the new page
          }
          lines.forEach((line) => {
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(10);
            pdf.text(10, yOffset, line);
            yOffset += lineHeight;
          });
        });

        yOffset += 6; // Add some padding between sections
      }
    });

    pdf.save(`Faculty Profile - ${facultyData?.name}.pdf`);
  };

  return (
    <div className="faculty-container">
      <h2 className="container-title">Search Faculty by Expertise Area</h2>
      <div className="table-container">
        {isLoading && <LoadingSpinner />}
        <div
          className={`faculty-search-data ${
            searchResults.length > 0 ? "" : "full-width"
          }`}
        >
          {/* {isLoading ? (
            // <div className="loading-spinner">Loading...</div>
            <LoadingSpinner />
          ) :  */}
          {searchResults.length > 0 ? (
            <>
              <Table
                columns={facultyColumns}
                data={searchResults}
                disableFilterOptionsForIds={["tags", "action"]}
              />
            </>
          ) : (
            !isLoading && (
              <p className="no-data-text">
                Search for interested topics in the search box above
              </p>
            )
          )}
        </div>
        {searchResults.length > 0 && (
          <div className="faculty-view-data">
            {showData ? (
              <div>
                {/* <button type="button" onClick={handleDownloadPdf}>
                  Download as PDF
                </button> */}
                <div className="pdf-icon right" onClick={handleDownloadPdf}>
                  <Tooltip title="Export Profile as PDF" placement="bottom-start">
                    <FontAwesomeIcon icon={faShareFromSquare} />
                  </Tooltip>
                </div>
                <div className="faculty-view-header">
                  <div className="faculty-info-details">
                    <div className="faculty-image-container">
                      <a
                        href={facultyData?.iSearchURL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="image-link-container"
                      >
                        <img
                          src={facultyData?.iSearchImageURL}
                          alt="Faculty Image"
                        />
                        <div className="image-overlay">
                          <span className="image-overlay-text">
                            Link to ASU Search
                          </span>
                        </div>
                      </a>
                      <a
                        href={`https://scholar.google.com/citations?user=${facultyData?.authorGoogleScholarId}&hl=en&oi=ao`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="gs-profile-btn"
                      >
                        Google Scholar Profile
                      </a>
                    </div>

                    <div className="faculty-info-container" ref={facultyInfoRef}>
                      <a
                        href={facultyData?.iSearchURL}
                        className="faculty-name"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {facultyData?.name}
                      </a>
                      <p className="faculty-designation">
                        {facultyData?.designation}
                      </p>
                      <div className="faculty-details">
                        <p>
                          <strong>Email:</strong> {facultyData?.email}
                        </p>
                        <p>
                          <strong>Phone No.:</strong> {facultyData?.phoneNumber}
                        </p>
                        <p>
                          <strong>Address:</strong> {facultyData?.address}
                        </p>
                        <p>
                          <strong>Department:</strong> {facultyData?.department}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faculty-citation-details" ref={facultyCitationRef}>
                    <div className="citations-summary">
                      <div className="stats">
                        <span className="stat-number">
                          {facultyData?.scholarCitedBy}
                        </span>
                        <span className="stat-description">
                          Total Citations
                        </span>
                      </div>
                      <div className="stats">
                        <span className="stat-number">
                          {facultyData?.scholarCitedBy5y}
                        </span>
                        <span className="stat-description">5y Citations</span>
                      </div>
                      <div className="stats">
                        <span className="stat-number">
                          {facultyData?.scholarPublicationCount}
                        </span>
                        <span className="stat-description">
                          Total Publications
                        </span>
                      </div>
                    </div>
                    <div className="citations-table">
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>All</th>
                            <th>Last 5y</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Citations</td>
                            <td>{facultyData?.scholarCitedBy}</td>
                            <td>{facultyData?.scholarCitedBy5y}</td>
                          </tr>
                          <tr>
                            <td>h-index</td>
                            <td>{facultyData?.scholarHindex}</td>
                            <td>{facultyData?.scholarHindex5y}</td>
                          </tr>
                          <tr>
                            <td>i10-index</td>
                            <td>{facultyData?.scholarI10Index}</td>
                            <td>{facultyData?.scholarI10Index5y}</td>
                          </tr>
                          <tr>
                            <td>Last Updated</td>
                            <td></td>
                            <td>
                              {new Date(
                                facultyData?.lastUpdated
                              ).toLocaleDateString(undefined, {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="faculty-view-body">
                  <span className="accordion-title">Interest Topics</span>
                  <div className="interest-topics">
                    {facultyData?.interestedTopics.map((topic, index) => (
                      <div className="interest-topic" key={index}>
                        {topic.interestTopics}
                      </div>
                    ))}
                  </div>
                  <div className="accordian-container">
                    {/* Biography Accordion */}
                    {facultyData?.bio && (
                      <div className="accordion">
                        <div
                          className="accordion-header"
                          onClick={() => toggleAccordion("biography")}
                        >
                          <span className="accordion-title">Biography</span>
                          <span
                            className={
                              accordionState.biography
                                ? "accordion-icon open"
                                : "accordion-icon"
                            }
                          >
                            +
                          </span>
                        </div>
                        {accordionState.biography && (
                          <div className="accordion-content">
                            {facultyData?.bio.split("\n").join("\n\n")}
                          </div>
                        )}
                      </div>
                    )}

                    {/* Research Accordion */}
                    {facultyData?.research && (
                      <div className="accordion">
                        <div
                          className="accordion-header"
                          onClick={() => toggleAccordion("research")}
                        >
                          <span className="accordion-title">Research</span>
                          <span
                            className={
                              accordionState.research
                                ? "accordion-icon open"
                                : "accordion-icon"
                            }
                          >
                            +
                          </span>
                        </div>
                        {accordionState.research && (
                          <div className="accordion-content">
                            {facultyData?.research.split(" \n").join("\n\n")}
                          </div>
                        )}
                      </div>
                    )}

                    {/* Public Work Accordion */}
                    {facultyData?.publicWork && (
                      <div className="accordion">
                        <div
                          className="accordion-header"
                          onClick={() => toggleAccordion("publicWork")}
                        >
                          <span className="accordion-title">Public Work</span>
                          <span
                            className={
                              accordionState.publicWork
                                ? "accordion-icon open"
                                : "accordion-icon"
                            }
                          >
                            +
                          </span>
                        </div>
                        {accordionState.publicWork && (
                          <div className="accordion-content">
                            {facultyData?.publicWork.split(" \n").join("\n\n")}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <p className="no-data-text">
                Click on view button for more details
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Expertise;
