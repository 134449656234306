import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Register.css";
import asu_ker_logo from "../../images/asu_ker_logo.png";
import { toast } from "react-toastify";
// import { postDataForRegistration } from "../Services/AccessAPI";
import SessionManager from "./SessionManager";
import { useHistory } from "react-router-dom";

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const history = useHistory();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "organization") {
      setOrganization(value);
    } else if (name === "title") {
      setTitle(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleInputFocus = (e) => {
    const inputContainer = e.target.parentNode;
    inputContainer.classList.add("focused");
  };

  const handleInputBlur = (e) => {
    const inputContainer = e.target.parentNode;
    if (e.target.value === "") {
      inputContainer.classList.remove("focused");
    }
  };

  const handleRegistration = async (e) => {
    e.preventDefault();

    // Check if password and confirm password match
    if (password !== confirmPassword) {
      toast.error("Password and Confirm Password do not match.");
      return;
    }

    let userInfo = {
      firstName,
      lastName,
      organization,
      title,
      email,
      password,
    };

    // postDataForRegistration("api/Register", userInfo).then((result) => {
    //   if (result?.data?.access_token) {
    //     SessionManager.setUserSession(
    //       result.data.access_token,
    //       result.data.userId,
    //       email
    //     );

    //     if (SessionManager.getToken()) {
    //       console.log("Registration successful!");
    //       console.log("User ID:", sessionStorage.getItem("userId"));

    //       // If registration successful and get token, you can redirect to another page.
    //       // history.push("/dashboard"); // Example of redirection
    //       window.location.href = "/dashboard"; // Redirect to the dashboard
    //     }
    //   } else {
    //     toast.error("Registration failed. Please check your information.");
    //   }
    // });
  };

  return (
    <div className="register-container">
      <div className="register-box">
        <img src={asu_ker_logo} alt="Register" className="login-image" />
        <h2>Registration - Knowledge Alliance Tool</h2>
        <form onSubmit={handleRegistration}>
          <div className="name-fields">
            <div className="input-container first-name">
              <input
                type="text"
                name="firstName"
                value={firstName}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                required
              />
              <label>First Name</label>
            </div>
            <div className="input-container">
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                required
              />
              <label>Last Name</label>
            </div>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="organization"
              value={organization}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              required
            />
            <label>Organization</label>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="title"
              value={title}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />
            <label>Title (Optional)</label>
          </div>
          <div className="input-container">
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              required
            />
            <label>Email ID</label>
          </div>
          <div className="input-container">
            <input
              type="password"
              name="password"
              value={password}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              required
            />
            <label>Password</label>
          </div>
          <div className="input-container">
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              required
            />
            <label>Confirm Password</label>
          </div>
          <button type="submit">Register</button>
        </form>
        <Link to="/login">
          <span className="login-btn">Back to login</span>
        </Link>
      </div>
    </div>
  );
};

export default Register;
