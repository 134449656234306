import React, { useState, useEffect, useRef } from "react";
import Table from "../Utilities/Table/Table";
import { getData, postDataForUpload } from "../Services/AccessAPI";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import "./Uploads.css";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import Select, { components } from "react-select";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex "
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

const allOptions = [
  { value: "option 1", label: "option 1" },
  { value: "option 2", label: "option 2" },
  { value: "option 3", label: "option 3" },
  { value: "option 4", label: "option 4" }
];

const Uploads = () => {
  const [uploadsData, setUploadsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let fetchURL = `api/KeywordUpload/KeywordsInfo`;
        const data = await getData(fetchURL);
        setUploadsData(data.Result);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchData();
  }, []);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (selectedFile === null) {
      toast.warn("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("files", selectedFile);

    try {
      const uploadsResponse = await postDataForUpload(
        "api/KeywordUpload/File_Upload",
        formData
      );

      const uploadsResponseData = await uploadsResponse.json();

      if (!uploadsResponse.ok) {
        toast.error("Failed to upload file!");
        toast.error(uploadsResponseData.message);
        throw new Error("Failed to upload file");
      }

      console.log("File uploaded successfully");
      // Set Uploads Table data
      const uploadsData = await getData(`api/KeywordUpload/KeywordsInfo`);
      // const data = await uploadsDataResponse.json();
      setUploadsData(uploadsData.Result);

      toast.success("File uploaded successfully!");
      setSelectedFile(null);
      fileInputRef.current.value = "";
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleDownload = async (file) => {
    try {
      const response = await getData(
        `api/KeywordUpload/DownloadCSV?keyword=${file.RealName}`,
        false
      );

      if (!response.ok) {
        toast.error("Failed to download file!");
        throw new Error("Failed to download file");
      }

      console.log("File downloaded successfully");

      // Retrieve the filename and original format from the Content-Disposition header
      const disposition = response.headers.get("Content-Disposition");
      const match = disposition && disposition.match(/filename\*=UTF-8''(.+)/);
      const filename = match ? match[1] : file.FileName;

      const blob = await response.blob();

      // Create a temporary download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;

      // Simulate a click on the download link
      link.click();

      // Clean up the temporary URL
      window.URL.revokeObjectURL(url);
      toast.success("File downloaded successfully!");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const uploadsColumns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "s.no",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: () => (
          <div className="custom-header">
            <span>File Name</span>
            <span className="count-badge">{uploadsData.length}</span>
          </div>
        ),
        accessor: "FileName",
        sortType: "alphanumeric",
      },
      {
        Header: "",
        accessor: "download",
        Cell: ({ row }) => (
          <div>
            <a
              className={`download-button${
                row.original.Processed === 0 ? " disabled" : ""
              }`}
              tabIndex="0"
              onClick={() => {
                handleDownload(row.original);
              }}
            >
              Download
            </a>
          </div>
        ),
      },
      {
        Header: "Uploaded Date",
        accessor: "date",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });
          return <span>{formattedDate}</span>;
        },
      },
    ],
    [uploadsData]
  );

  return (
    <div className="uploads-container">
      <div className="uploads-header">
        <div className="uploads-title">
          <div className="background-image" />
          <h1 className="section-title">
            <span className="black-on-gold">Keywords Upload</span>
          </h1>
          <p>
            Upload one or more combinations (upto five) of keywords to get a
            matrix with each keyword count present in the faculty/researchers
            and nonprofit organization profile.
          </p>
        </div>
        {/* Multiselect Dropdown */}
        {/* <div className="dropdown-container">
          <Select
            defaultValue={[]}
            isMulti
            placeholder="Select where to match the uploaded file..." 
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={(options) => {
              if (Array.isArray(options)) {
                setSelectedOptions(options.map((opt) => opt.value));
              }
            }}
            options={allOptions}
            components={{
              Option: InputOption,
            }}
          />
        </div> */}
        <div className="upload-box-container">
          <input
            type="file"
            id="csvFile"
            accept=".csv"
            className="file-upload-input"
            ref={fileInputRef}
            onChange={handleFileInputChange}
          />
          <button
            type="submit"
            className="upload-button"
            onClick={handleUpload}
          >
            <FontAwesomeIcon icon={faUpload} />
            Upload
          </button>
        </div>
        <div className="table-container">
          {isLoading && <LoadingSpinner />}
          <div className="uploads-main-panel">
            {uploadsData.length > 0 ? (
              <>
                <Table
                  columns={uploadsColumns}
                  data={uploadsData}
                  disableFilterOptionsForIds={["download", "s.no"]}
                  tableDataHeight={"260px"}
                />
              </>
            ) : (
              <p className="no-data-text">No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uploads;
