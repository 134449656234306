// images.js

import image1 from '../../images/SDG_Icons/E-WEB-Goal-01.png';
import image2 from '../../images/SDG_Icons/E-WEB-Goal-02.png';
import image3 from '../../images/SDG_Icons/E-WEB-Goal-03.png';
import image4 from '../../images/SDG_Icons/E-WEB-Goal-04.png';
import image5 from '../../images/SDG_Icons/E-WEB-Goal-05.png';
import image6 from '../../images/SDG_Icons/E-WEB-Goal-06.png';
import image7 from '../../images/SDG_Icons/E-WEB-Goal-07.png';
import image8 from '../../images/SDG_Icons/E-WEB-Goal-08.png';
import image9 from '../../images/SDG_Icons/E-WEB-Goal-09.png';
import image10 from '../../images/SDG_Icons/E-WEB-Goal-10.png';
import image11 from '../../images/SDG_Icons/E-WEB-Goal-11.png';
import image12 from '../../images/SDG_Icons/E-WEB-Goal-12.png';
import image13 from '../../images/SDG_Icons/E-WEB-Goal-13.png';
import image14 from '../../images/SDG_Icons/E-WEB-Goal-14.png';
import image15 from '../../images/SDG_Icons/E-WEB-Goal-15.png';
import image16 from '../../images/SDG_Icons/E-WEB-Goal-16.png';
import image17 from '../../images/SDG_Icons/E-WEB-Goal-17.png';

const sustainableGoalsImages = [
  {
    image: image1,
    title: 'poverty alleviation',
  },
  {
    image: image2,
    title: 'food insecurity',
  },
  {
    image: image3,
    title: 'health wellness',
  },
  {
    image: image4,
    title: 'higher education',
  },
  {
    image: image5,
    title: 'gender equality',
  },
  {
    image: image6,
    title: 'water sanitation',
  },
  {
    image: image7,
    title: 'energy environmental',
  },
  {
    image: image8,
    title: 'economic development',
  },
  {
    image: image9,
    title: 'building resilient',
  },
  {
    image: image10,
    title: 'income inequality',
  },
  {
    image: image11,
    title: 'urban sustainability',
  },
  {
    image: image12,
    title: 'sustainable agriculture',
  },
  {
    image: image13,
    title: 'climate change',
  },
  {
    image: image14,
    title: 'water conservation',
  },
  {
    image: image15,
    title: 'watershed management',
  },
  {
    image: image16,
    title: 'social justice',
  },
  {
    image: image17,
    title: 'strategic partnerships',
  },
];

export default sustainableGoalsImages;
