import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch, faTags, faUpload, faRocket, faBolt, faInfo, faBars, faTimes, faTag, faCircleInfo, faUsersRectangle } from "@fortawesome/free-solid-svg-icons";
import { faSearchengin } from "@fortawesome/free-brands-svg-icons";
import "./Sidebar.css";

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getIcon = (icon, label, fadeIcon = false) => {
    if (isCollapsed) {
      return (
        <div className="collapsed-icon">
          <FontAwesomeIcon icon={icon} beatFade={fadeIcon}/>
          <span>{label}</span>
        </div>
      );
    } else {
      return (
        <div className="expanded-icon">
          <FontAwesomeIcon icon={icon} fade={fadeIcon}/>
          <span>{label}</span>
        </div>
      );
    }
  };

  const isActiveRoute = (routePath) => {
    return location.pathname === routePath ? "active" : "";
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <button onClick={toggleCollapse} className="toggle-button">
        {isCollapsed ? getIcon(faBars) : getIcon(faTimes)}
      </button>
      <div className={`nav ${isCollapsed ? "collapsed" : ""}`}>
        <Link to="/" className={`nav-link ${isActiveRoute("/dashboard")}`}>
          {getIcon(faHome, "Home")}
        </Link>
        <Link to="/search" className={`nav-link ${isActiveRoute("/search")}`}>
          {getIcon(faSearch, "Search")}
        </Link>
        <Link to="/moonshot" className={`nav-link ${isActiveRoute("/moonshot")}`}>
          {getIcon(faRocket, "Moonshot")}
        </Link>
        {<Link to="/aiirsearch" className={`nav-link ${isActiveRoute("/aiirsearch")}`}>
          {getIcon(faBolt, "AIIR", true)}
        </Link>}
        <Link to="/tags" className={`nav-link ${isActiveRoute("/tags")}`}>
          {getIcon(faTags, "Tags")}
        </Link>
        <Link to="/upload" className={`nav-link ${isActiveRoute("/upload")}`}>
          {getIcon(faUpload, "Upload")}
        </Link>
        {/* <Link to="/documentation" className={`nav-link ${isActiveRoute("/documentation")}`}>
          {getIcon(faBook, "Documentation")}
        </Link> */}
        <Link to="/about" className={`nav-link ${isActiveRoute("/about")}`}>
          {getIcon(faCircleInfo, "About")}
        </Link>
        <Link to="/people" className={`nav-link ${isActiveRoute("/people")}`}>
          {getIcon(faUsersRectangle, "People")}
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
