import React, { useState, useEffect, useCallback } from "react";
import Table from "../Utilities/Table/Table";
import { getData, postData } from "../Services/AccessAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar as solidStar,
  faEyeSlash,
  faEye,
  faFileCsv
} from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import sustainableGoalsImages from "./SustainableGoalsImages";
import "./SharedTopics.css";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

const SharedTopics = ({ searchTerm, updateSearchTerm }) => {
  const [updatedSearchTerm, setUpdatedSearchTerm] = useState(searchTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [activeMainTab, setActiveMainTab] = useState("OrgToFaculty");
  const [activeTab, setActiveTab] = useState("details");
  const [sourceData, setSourceData] = useState(null);
  const [targetData, setTargetData] = useState(null);
  const [commonBigrams, setCommonBigrams] = useState([]);
  const [hiddenBigrams, sethiddenBigrams] = useState([]);
  const [favoriteBigrams, setFavoriteBigrams] = useState([]);
  const currentOrgName = React.useRef(null);
  const currentFacultyName = React.useRef(null);
  const [sharedTopicsTitle, setSharedTopicsTitle] = useState(
    "Shared topics among organizations and researchers"
  );
  const [viewButtonClickedIn, setViewButtonClickedIn] = useState("");
  const [sharedTopicsDataCsv, setSharedTopicsDataCsv] = useState([[]]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      setIsLoading(true);
      try {
        let fetchURL;
        let geoData = sessionStorage.getItem("userLocation") || null;
        if (activeMainTab === "OrgToFaculty") {
          fetchURL =
            updatedSearchTerm !== ""
              ? `api/Home/getOrgToFacultySearchResults?searchKeyword=${updatedSearchTerm}`
              : `api/Home/getDefaultOrgToFaculty`;
        } else if (activeMainTab === "OrgToOrg") {
          fetchURL =
            updatedSearchTerm !== ""
              ? `api/Home/getOrgToOrgSearchResults?searchKeyword=${updatedSearchTerm}`
              : `api/Home/getDefaultOrgToOrg`;
        } else {
          fetchURL =
            updatedSearchTerm !== ""
              ? `api/Home/getFacultyToFacultySearchResults?searchKeyword=${updatedSearchTerm}`
              : `api/Home/getDefaultFacultyToFaculty`;
        }

        if (geoData && updatedSearchTerm !== "") {
          fetchURL += `&geoData=${geoData}`;
        }

        const data = await getData(fetchURL);

        if (data.status === 404) {
          setIsLoading(false);
          return;
        }

        const searchResultsData =
          activeMainTab === "OrgToFaculty"
            ? updatedSearchTerm !== ""
              ? data.message
              : data
            : updatedSearchTerm !== ""
            ? data.message
            : data.result;

        setSearchResults(searchResultsData);
        setIsLoading(false);
      } catch (error) {
        toast.error("Error fetching data");
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchSearchResults();
  }, [updatedSearchTerm, activeMainTab]);

  useEffect(() => {
    setUpdatedSearchTerm(searchTerm);
  }, [searchTerm]);

  // To set Shared Topics CSV data
  useEffect(() => {
    if (sourceData && targetData) {
      const sharedTopicsCsv = [];

      // Helper function to ensure URLs start with http:// or https://
      const formatUrl = (url) =>
        url.startsWith("http") ? url : `https://${url}`;

      // Functions to handle data pushing based on the active tab
      const pushOrgToFacultyData = () => {
        sharedTopicsCsv.push([
          sourceData.organizationName,
          targetData.facultyName,
        ]);
        sharedTopicsCsv.push([
          formatUrl(sourceData.url),
          formatUrl(targetData.isearch),
        ]);
        sharedTopicsCsv.push([
          sourceData.organizationAddress,
          targetData.department1,
        ]);
      };

      const pushOrgToOrgData = () => {
        sharedTopicsCsv.push([
          sourceData.organizationName,
          targetData.organizationName,
        ]);
        sharedTopicsCsv.push([
          formatUrl(sourceData.url),
          formatUrl(targetData.url),
        ]);
        sharedTopicsCsv.push([
          sourceData.organizationAddress,
          targetData.organizationAddress,
        ]);
      };

      const pushFacultyToFacultyData = () => {
        sharedTopicsCsv.push([sourceData.facultyName, targetData.facultyName]);
        sharedTopicsCsv.push([
          formatUrl(sourceData.isearch),
          formatUrl(targetData.isearch),
        ]);
        sharedTopicsCsv.push([sourceData.department1, targetData.department1]);
      };

      // Execute the appropriate function based on the activeMainTab
      if (activeMainTab === "OrgToFaculty") pushOrgToFacultyData();
      else if (activeMainTab === "OrgToOrg") pushOrgToOrgData();
      else if (activeMainTab === "FacultyToFaculty") pushFacultyToFacultyData();

      // Add shared topics
      sharedTopicsCsv.push([""]);
      sharedTopicsCsv.push(["#", "Shared Topics"]);
      commonBigrams.forEach((item, index) => {
        sharedTopicsCsv.push([index + 1, item.bigrams]);
      });

      setSharedTopicsDataCsv(sharedTopicsCsv);
    }
  }, [commonBigrams]);

  const handleViewButtonClick = async (orgName, facultyName) => {
    setIsLoading(true);
    currentOrgName.current = orgName;
    currentFacultyName.current = facultyName;

    const orgDetailsURL = `api/Home/getOrganizationDetails?organizationName=${encodeURIComponent(
      orgName
    )}`;
    const facultyDetailsURL = `api/Home/getFacultyDetails/${encodeURIComponent(
      facultyName
    )}`;
    const commonBigramsURL = `api/Home/getCommonBigrams?organizationName=${encodeURIComponent(
      orgName
    )}&facultyName=${encodeURIComponent(facultyName)}&edgeType=View`;

    try {
      const [
        orgData,
        facultyData,
        commonBigrams,
        hiddenBigrams,
        favoriteBigrams,
      ] = await Promise.all([
        getData(orgDetailsURL),
        getData(facultyDetailsURL),
        getData(commonBigramsURL),
        getData(`api/Home/getHiddenBigrams`),
        getData(`api/Home/getFavouriteBigrams`),
      ]);

      setSourceData(orgData.message);
      setTargetData(facultyData.message);
      setCommonBigrams(commonBigrams.message);
      sethiddenBigrams(hiddenBigrams.message);
      setFavoriteBigrams(favoriteBigrams.message);

      setActiveTab("details");
      setShowData(true);
      setIsLoading(false);
      setViewButtonClickedIn(activeMainTab);
    } catch (error) {
      toast.error("Error fetching data");
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleViewButtonClickForSourceTargetColumns = async (
    source,
    target
  ) => {
    currentOrgName.current = source;
    currentFacultyName.current = target;
    const isOrgToOrg = activeMainTab === "OrgToOrg";
    const sourceDataURL = isOrgToOrg
      ? `api/Home/getOrganizationDetails?organizationName=${encodeURIComponent(
          source
        )}`
      : `api/Home/getFacultyDetails/${encodeURIComponent(source)}`;

    const targetDataURL = isOrgToOrg
      ? `api/Home/getOrganizationDetails?organizationName=${encodeURIComponent(
          target
        )}`
      : `api/Home/getFacultyDetails/${encodeURIComponent(target)}`;

    const commonBigramsURL = isOrgToOrg
      ? `api/Home/getCommonBigramsListForOrgToOrg?source=${encodeURIComponent(
          currentOrgName.current
        )}&target=${encodeURIComponent(
          currentFacultyName.current
        )}&edgeType=View`
      : `api/Home/getCommonBigramsListForFacultyToFaculty?source=${encodeURIComponent(
          currentOrgName.current
        )}&target=${encodeURIComponent(
          currentFacultyName.current
        )}&edgeType=View`;

    try {
      setIsLoading(true);

      const [
        sourceData,
        targetData,
        commonBigrams,
        hiddenBigrams,
        favoriteBigrams,
      ] = await Promise.all([
        getData(sourceDataURL),
        getData(targetDataURL),
        getData(commonBigramsURL),
        getData(`api/Home/getHiddenBigrams`),
        getData(`api/Home/getFavouriteBigrams`),
      ]);

      setSourceData(sourceData.message);
      setTargetData(targetData.message);
      setCommonBigrams(commonBigrams.message);
      sethiddenBigrams(hiddenBigrams.message);
      setFavoriteBigrams(favoriteBigrams.message);
      setActiveTab("details");
      setShowData(true);
      setIsLoading(false);
      setViewButtonClickedIn(activeMainTab);
    } catch (error) {
      toast.error("Error fetching data");
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleHideBigram = async (bigram) => {
    try {
      let insertHideBigramURL, commonBigramsURL, hiddenBigramsURL;
      const bigramInfo = { bigram };

      insertHideBigramURL = "api/Home/insertHiddenBigrams";
      hiddenBigramsURL = "api/Home/getHiddenBigrams";

      switch (activeMainTab) {
        case "OrgToFaculty":
          commonBigramsURL = `api/Home/getCommonBigrams?organizationName=${encodeURIComponent(
            currentOrgName.current
          )}&facultyName=${encodeURIComponent(
            currentFacultyName.current
          )}&edgeType=View`;
          break;

        case "OrgToOrg":
          commonBigramsURL = `api/Home/getCommonBigramsListForOrgToOrg?source=${encodeURIComponent(
            currentOrgName.current
          )}&target=${encodeURIComponent(
            currentFacultyName.current
          )}&edgeType=View`;
          break;

        case "FacultyToFaculty":
          commonBigramsURL = `api/Home/getCommonBigramsListForFacultyToFaculty?source=${encodeURIComponent(
            currentOrgName.current
          )}&target=${encodeURIComponent(
            currentFacultyName.current
          )}&edgeType=View`;
          break;

        default:
          throw new Error("Invalid activeMainTab value.");
      }

      const insertHideBigramResponse = await postData(
        insertHideBigramURL,
        bigramInfo
      );
      const commonBigrams = await getData(commonBigramsURL);
      const hiddenBigrams = await getData(hiddenBigramsURL);

      if (!insertHideBigramResponse.ok) {
        throw new Error("Failed to hide bigram.");
      }

      setCommonBigrams(commonBigrams.message);
      sethiddenBigrams(hiddenBigrams.message);
    } catch (error) {
      toast.error("Error hiding bigram");
      console.error("Error hiding bigram:", error);
    }
  };

  const handleUnhideBigram = async (bigram) => {
    try {
      let unHideBigramURL, commonBigramsURL, hiddenBigramsURL;
      const bigramInfo = { bigram };

      unHideBigramURL = "api/Home/unhideBigrams";
      hiddenBigramsURL = "api/Home/getHiddenBigrams";

      switch (activeMainTab) {
        case "OrgToFaculty":
          commonBigramsURL = `api/Home/getCommonBigrams?organizationName=${encodeURIComponent(
            currentOrgName.current
          )}&facultyName=${encodeURIComponent(
            currentFacultyName.current
          )}&edgeType=View`;
          break;

        case "OrgToOrg":
          commonBigramsURL = `api/Home/getCommonBigramsListForOrgToOrg?source=${encodeURIComponent(
            currentOrgName.current
          )}&target=${encodeURIComponent(
            currentFacultyName.current
          )}&edgeType=View`;
          break;

        case "FacultyToFaculty":
          commonBigramsURL = `api/Home/getCommonBigramsListForFacultyToFaculty?source=${encodeURIComponent(
            currentOrgName.current
          )}&target=${encodeURIComponent(
            currentFacultyName.current
          )}&edgeType=View`;
          break;

        default:
          throw new Error("Invalid activeMainTab value.");
      }

      const unHideBigramResponse = await postData(unHideBigramURL, bigramInfo);
      const commonBigrams = await getData(commonBigramsURL);
      const hiddenBigrams = await getData(hiddenBigramsURL);

      if (!unHideBigramResponse.ok) {
        throw new Error("Failed to unhide bigram.");
      }

      setCommonBigrams(commonBigrams.message);
      sethiddenBigrams(hiddenBigrams.message);
    } catch (error) {
      toast.error("Error unhiding bigram");
      console.error("Error unhiding bigram:", error);
    }
  };

  const handleGoalImageClick = (goal) => {
    // Actual code commented below
    // updateSearchTerm(goal); // To set the correct search text
    // setUpdatedSearchTerm(goal);

    // Temporary under development message
    toast.warn("This section is under development. Check back soon!");
  };

  const handleToggleFavoriteBigram = useCallback(
    async (bigram) => {
      let fetchURL, successMessage, errorMessage;
      let bigramInfo = {
        bigram: bigram,
      };
      if (favoriteBigrams.some((item) => item.bigrams === bigram)) {
        fetchURL = "api/Home/removeBigramFromFav";
        successMessage = "Successfully removed from Favorites";
        errorMessage = "Failed to remove from Favorites";
      } else {
        fetchURL = "api/Home/insertFavouriteBigrams";
        successMessage = "Successfully added to Favorites";
        errorMessage = "Failed to add to Favorites";
      }
      try {
        const toggleFavoritesResponse = await postData(fetchURL, bigramInfo);

        if (!toggleFavoritesResponse.ok) {
          throw new Error(errorMessage);
        }

        //Get Favorite Bigrams
        const favoriteBigrams = await getData(`api/Home/getFavouriteBigrams`);
        setFavoriteBigrams(favoriteBigrams.message);
      } catch (error) {
        console.error(errorMessage, error);
      }
    },
    [favoriteBigrams]
  );

  const isFavorite = useCallback(
    (bigram) => {
      return favoriteBigrams.some((item) => item.bigrams === bigram);
    },
    [favoriteBigrams]
  );

  const orgToFacultyColumns = React.useMemo(
    () => [
      {
        Header: "Organization",
        accessor: "organizationName",
        sortType: "alphanumeric",
      },
      {
        Header: "Faculty",
        accessor: "facultyName",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => {
          const renderExportLink = () => {
            if (
              currentOrgName.current === row.original.organizationName &&
              currentFacultyName.current === row.original.facultyName
            ) {
              return (
                <>
                  <CSVLink
                    data={sharedTopicsDataCsv}
                    filename={`Shared_Topics_${currentOrgName.current}_${currentFacultyName.current}.csv`}
                  >
                    <Tooltip
                      title="Export Shared Topics as CSV"
                      placement="bottom-end"
                    >
                      <span className="export-icon">
                        <FontAwesomeIcon icon={faFileCsv} />
                      </span>
                    </Tooltip>
                  </CSVLink>
                </>
              );
            }
            return null;
          };

          return (
            <>
              <a
                className="view-button"
                tabIndex="0"
                onClick={() =>
                  handleViewButtonClick(
                    row.original.organizationName,
                    row.original.facultyName
                  )
                }
              >
                View
              </a>
              {renderExportLink()}
            </>
          );
        },
      },
    ],
    [handleViewButtonClick]
  );

  const sourceTargetColumns = React.useMemo(
    () => [
      {
        Header:
          activeMainTab === "OrgToOrg"
            ? "Source Organization"
            : "Source Faculty",
        accessor: "source",
      },
      {
        Header:
          activeMainTab === "OrgToOrg"
            ? "Target Organization"
            : "Target Faculty",
        accessor: "target",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => {
          const renderExportLink = () => {
            if (
              currentOrgName.current === row.original.source &&
              currentFacultyName.current === row.original.target
            ) {
              return (
                <>
                  <CSVLink
                    data={sharedTopicsDataCsv}
                    filename={`Shared_Topics_${currentOrgName.current}_${currentFacultyName.current}.csv`}
                  >
                    <Tooltip
                      title="Export Shared Topics as CSV"
                      placement="bottom-end"
                    >
                      <span className="export-icon">
                        <FontAwesomeIcon icon={faFileCsv} />
                      </span>
                    </Tooltip>
                  </CSVLink>
                </>
              );
            }
            return null;
          };

          return (
            <>
              <a
                className="view-button"
                tabIndex="0"
                onClick={() =>
                  handleViewButtonClickForSourceTargetColumns(
                    row.original.source,
                    row.original.target
                  )
                }
              >
                View
              </a>
              {renderExportLink()}
            </>
          );
        },
      },
    ],
    [handleViewButtonClickForSourceTargetColumns]
  );

  const sharedTopicsColumns =
    activeMainTab === "OrgToFaculty"
      ? orgToFacultyColumns
      : sourceTargetColumns;

  const detailsColumns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "s.no",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Favorite",
        accessor: "favorite",
        Cell: ({ row }) => (
          <span
            onClick={() => handleToggleFavoriteBigram(row.original.bigrams)}
          >
            <Tooltip
              title={`${
                isFavorite(row.original.bigrams)
                  ? "Remove from Favorites"
                  : "Add to Favorites"
              }`}
              placement="bottom-start"
            >
              <FontAwesomeIcon
                icon={solidStar}
                className={`star-icon ${
                  isFavorite(row.original.bigrams) ? "gold" : ""
                }`}
              />
            </Tooltip>
          </span>
        ),
      },
      {
        Header: "Shared Topic",
        accessor: "bigrams",
      },
      {
        Header: "Hide",
        accessor: "hide",
        Cell: ({ row }) => (
          <span
            className={`hide-icon ${
              isFavorite(row.original.bigrams) ? "disabled" : ""
            }`}
            onClick={() => handleHideBigram(row.original.bigrams)}
          >
            <Tooltip
              title={`${
                isFavorite(row.original.bigrams)
                  ? "Cannot hide Favorite"
                  : "Hide topic"
              }`}
              placement="bottom-start"
            >
              <FontAwesomeIcon icon={faEyeSlash} />
            </Tooltip>
          </span>
        ),
      },
    ],
    [isFavorite, handleToggleFavoriteBigram, handleHideBigram]
  );

  const hiddenBigramsColumns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "s.no",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Shared Topic",
        accessor: "bigrams",
      },
      {
        Header: "Hidden Date",
        accessor: "hiddenDate",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleDateString(undefined, {
            day: "numeric",
            month: "short",
            year: "numeric",
          });
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "Unhide",
        accessor: "unhide",
        Cell: ({ row }) => (
          <span
            className="unhide-icon"
            onClick={() => handleUnhideBigram(row.original.bigrams)}
          >
            <Tooltip title="Unhide topic" placement="bottom-start">
              <FontAwesomeIcon icon={faEye} className="unhide-icon" />
            </Tooltip>
          </span>
        ),
      },
    ],
    [handleUnhideBigram]
  );

  const favoriteBigramsColumns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "s.no",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Shared Topic",
        accessor: "bigrams",
      },
      {
        Header: "Modified Date",
        accessor: "hiddenDate",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleDateString(undefined, {
            day: "numeric",
            month: "short",
            year: "numeric",
          });
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "",
        accessor: "unfavorite",
        Cell: ({ row }) => (
          <span
            onClick={() => handleToggleFavoriteBigram(row.original.bigrams)}
          >
            <Tooltip
              title={`${
                isFavorite(row.original.bigrams)
                  ? "Remove from Favorites"
                  : "Add to Favorites"
              }`}
              placement="bottom-start"
            >
              <FontAwesomeIcon
                icon={solidStar}
                className={`star-icon ${
                  isFavorite(row.original.bigrams) ? "gold" : ""
                }`}
              />
            </Tooltip>
          </span>
        ),
      },
    ],
    [isFavorite, handleToggleFavoriteBigram]
  );

  const handleMainTabClick = (tab) => {
    setActiveMainTab(tab);
    setSharedTopicsTitle(
      tab === "OrgToFaculty"
        ? "Shared topics among organizations and researchers"
        : tab === "OrgToOrg"
        ? "Shared topics among organizations"
        : "Shared topics among researchers"
    );
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const setOrgDetails = (orgData) => {
    return (
      <div className="details-org-data">
        <a
          href={
            orgData?.url?.startsWith("http")
              ? orgData?.url
              : `https://${orgData?.url}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="data-title"
        >
          {`${orgData?.organizationName ? orgData.organizationName : ""} ${
            orgData?.designation ? `(${orgData?.designation})` : ""
          }`}
        </a>
        <div className="data-footer">{orgData?.organizationAddress}</div>
      </div>
    );
  };

  const setFacultyDetails = (facultyData) => {
    return (
      <div className="details-faculty-data">
        <a
          href={
            facultyData?.isearch?.startsWith("http")
              ? facultyData?.isearch
              : `https://${facultyData?.isearch}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="data-title"
        >
          {`${facultyData?.facultyName ? facultyData.facultyName : ""} ${
            facultyData?.facultyDesignation
              ? `(${facultyData?.facultyDesignation})`
              : ""
          }`}
        </a>
        <div className="data-footer">{facultyData?.department1}</div>
      </div>
    );
  };

  return (
    <div className="shared-topics-container">
      <h2 className="container-title">
        {/* Shared topics among organizations and researchers */}
        {sharedTopicsTitle}
      </h2>
      <div className="shared-topics-main-tabs">
        <Tooltip
          title="Shared topics among organizations and researchers"
          placement="bottom-start"
        >
          <div
            className={`shared-topics-tab ${
              activeMainTab === "OrgToFaculty" ? "active" : ""
            }`}
            onClick={() => handleMainTabClick("OrgToFaculty")}
          >
            Org To Faculty
          </div>
        </Tooltip>
        <Tooltip
          title="Shared topics among organizations"
          placement="bottom-start"
        >
          <div
            className={`shared-topics-tab ${
              activeMainTab === "OrgToOrg" ? "active" : ""
            }`}
            onClick={() => handleMainTabClick("OrgToOrg")}
          >
            Org To Org
          </div>
        </Tooltip>
        <Tooltip
          title="Shared topics among researchers"
          placement="bottom-start"
        >
          <div
            className={`shared-topics-tab ${
              activeMainTab === "FacultyToFaculty" ? "active" : ""
            }`}
            onClick={() => handleMainTabClick("FacultyToFaculty")}
          >
            Faculty To Faculty
          </div>
        </Tooltip>
      </div>
      <div className="table-container">
        {isLoading && <LoadingSpinner />}
        <div className="shared-topics-main-panel">
          {searchResults.length > 0 ? (
            <>
              <Table
                columns={sharedTopicsColumns}
                data={searchResults}
                disableFilterOptionsForIds={["action"]}
              />
            </>
          ) : (
            !isLoading && <p className="no-data-text">No matches found</p>
          )}
        </div>
        <div className="shared-topics-side-panel">
          <div className="shared-topics-tabs">
            {/* <div
              className={`shared-topics-tab ${
                activeTab === "faculty-to-org" ? "active" : ""
              }`}
              onClick={() => handleTabClick("faculty-to-org")}
            >
              Faculty to Org
            </div>
            <div
              className={`shared-topics-tab ${
                activeTab === "org-to-faculty" ? "active" : ""
              }`}
              onClick={() => handleTabClick("org-to-faculty")}
            >
              Org to Faculty
            </div> */}
            <div
              className={`shared-topics-tab ${
                activeTab === "details" ? "active" : ""
              }`}
              onClick={() => handleTabClick("details")}
            >
              Details
            </div>
            <div
              className={`shared-topics-tab ${
                activeTab === "favorites" ? "active" : ""
              }`}
              onClick={() => handleTabClick("favorites")}
            >
              Favorites
            </div>
            <div
              className={`shared-topics-tab ${
                activeTab === "hidden-topics" ? "active" : ""
              }`}
              onClick={() => handleTabClick("hidden-topics")}
            >
              Hidden Topics
            </div>
          </div>
          <div className="side-panel-data-container">
            {showData ? (
              <div>
                {activeTab === "details" && (
                  <div className="details-data">
                    <div className="details-data-header">
                      {viewButtonClickedIn === "OrgToFaculty" && (
                        <>
                          {setOrgDetails(sourceData)}
                          {setFacultyDetails(targetData)}
                        </>
                      )}
                      {viewButtonClickedIn === "OrgToOrg" && (
                        <>
                          {setOrgDetails(sourceData)}
                          {setOrgDetails(targetData)}
                        </>
                      )}
                      {viewButtonClickedIn === "FacultyToFaculty" && (
                        <>
                          {setFacultyDetails(sourceData)}
                          {setFacultyDetails(targetData)}
                        </>
                      )}
                      {/* <div className="details-org-data">
                        <a
                          href={
                            orgData?.url?.startsWith("http")
                              ? orgData?.url
                              : `http://${orgData?.url}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="data-title"
                        >
                          {`${orgData?.organizationName} ${
                            orgData?.designation
                              ? `(${orgData?.designation})`
                              : ""
                          }`}
                        </a>
                        <div className="data-footer">
                          {orgData?.organizationAddress}
                        </div>
                      </div>
                      <div className="details-faculty-data">
                        <a
                          href={
                            facultyData?.isearch?.startsWith("http")
                              ? facultyData?.isearch
                              : `http://${facultyData?.isearch}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="data-title"
                        >
                          {`${facultyData?.facultyName} ${
                            facultyData?.facultyDesignation
                              ? `(${facultyData?.facultyDesignation})`
                              : ""
                          }`}
                        </a>
                        <div className="data-footer">
                          {facultyData?.department1}
                        </div>
                      </div> */}
                    </div>
                    <div className="details-data-table">
                      <Table
                        columns={detailsColumns}
                        data={commonBigrams}
                        // showFilterOptions={false}
                        disableFilterOptionsForIds={[
                          "s.no",
                          "favorite",
                          "hide",
                        ]}
                        tableDataHeight="269px"
                      />
                    </div>
                  </div>
                )}

                {activeTab === "hidden-topics" && (
                  <div className="hidden-topics-table">
                    <Table
                      columns={hiddenBigramsColumns}
                      data={hiddenBigrams}
                      //showFilterOptions={false}
                      disableFilterOptionsForIds={["s.no", "unhide"]}
                      tableDataHeight="359px"
                    />
                  </div>
                )}

                {activeTab === "favorites" && (
                  <div className="favorites-table">
                    <Table
                      columns={favoriteBigramsColumns}
                      data={favoriteBigrams}
                      //showFilterOptions={false}
                      disableFilterOptionsForIds={["s.no", "unfavorite"]}
                      tableDataHeight="359px"
                    />
                  </div>
                )}
              </div>
            ) : (
              <p className="no-data-text">
                {isLoading ? "" : "Click on view button for more details"}
              </p>
            )}
          </div>
        </div>
      </div>
      <h2 className="container-title goals-title">
        Sustainable Development Goals
      </h2>
      <div className="goals-container">
        {sustainableGoalsImages.map((item, index) => (
          <div key={index}>
            <img
              src={item.image}
              alt={item.title}
              className="goal-image"
              onClick={() => handleGoalImageClick(item.title)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SharedTopics;
