import React from "react";
import interesting_finds_1 from "../../images/interesting_finds_1.jpg";
import "./InterestingFinds.css";

const InterestingFinds = () => {
  const handleViewCaseStudies = () => {
    window.open("https://resilience.asu.edu/case-studies", "_blank");
  };

  return (
    <div>
      <h2 className="container-title">#Interesting Finds</h2>
      <div className="image-container">
        <img
          className="image-find-1"
          src={interesting_finds_1}
          alt="Interesting Find 1"
        />
        <span className="image-text black-on-gold">
          Case studies in resilience
        </span>
      </div>
      <div className="details-container">
        <span className="details-text">
          The Knowledge Exchange for Resilience is committed to solving our most
          perplexing problems in four distinct, yet interrelated FUSE areas,
          diagrammed at right. Read our growing catalog of case studies
          highlighting use-inspired research that builds community resilience.
        </span>
        <button className="view-case-studies-button" onClick={handleViewCaseStudies}>
          View Case Studies
        </button>
      </div>
    </div>
  );
};

export default InterestingFinds;
