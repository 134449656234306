import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./ColumnFilter.css";
import { Tooltip } from "@mui/material";

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;

  const clearFilter = () => {
    setFilter("");
  };

  return (
    <span>
      <input
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
        className="filter-box"
        autoFocus
      />
      {filterValue && (
        <Tooltip title="Clear Search" placement="bottom-start">
        <span className="clear-icon" onClick={clearFilter}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        </Tooltip>
      )}
    </span>
  );
};
