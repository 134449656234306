import React, { useState } from "react";
import "./Login.css";
import asu_ker_logo from "../../images/asu_ker_logo.png";
import { toast } from "react-toastify";
import { postDataForLogin } from "../Services/AccessAPI";
import SessionManager from "./SessionManager";
import { Link, useHistory } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleInputFocus = (e) => {
    const inputContainer = e.target.parentNode;
    inputContainer.classList.add("focused");
  };

  const handleInputBlur = (e) => {
    const inputContainer = e.target.parentNode;
    if (e.target.value === "") {
      inputContainer.classList.remove("focused");
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userInfo = {
      email: username,
      password: password,
      rememberMe: true,
    };

    postDataForLogin("api/Login", userInfo).then((result) => {
      if (result?.data?.access_token) {
        SessionManager.setUserSession(
          result.data.access_token,
          result.data.userId,
          username
        );

        if (SessionManager.getToken()) {
          console.log("Login successful!");
          // If login successful and get token
          // redirect to dashboard
          // history.push("/dashboard");
          window.location.href = "/dashboard";
        }
      } else {
        toast.error("Incorrect Username or Password");
      }
    });
  };

  return (
    <div>
      {/* <App /> */}
      <div className="login-container">
        <div className="login-box">
          <img src={asu_ker_logo} alt="Login" className="login-image" />
          <h2>Knowledge Alliance Tool</h2>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <input
                type="text"
                name="username"
                value={username}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyDown={onKeyDown}
                required
              />
              <label>Username or Email</label>
            </div>
            <div className="input-container">
              <input
                type="password"
                name="password"
                value={password}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyDown={onKeyDown}
                required
              />
              <label>Password</label>
            </div>
            <button type="submit">Log in</button>
          </form>
          {/* <Link to="/register">
            <span className="register-btn">New user? Register</span>
          </Link> */}
        </div>
        <div className="login-footer-info">
          <h1>Knowledge Exchange For Resilience</h1>
          <p>©2018 All Rights Reserved. Knowledge Exchange For Resilience!</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
