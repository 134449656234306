import SessionManager from "../Auth/SessionManager";
import { BASE_URL, FAST_API_BASE_URL } from "./Settings";

const fetchData = async (endPoint, method, inputObj, returnJSON = false) => {
    const token = SessionManager.getToken();
    const headers = {
        "access-control-allow-origin": "*",
        // 'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    };

    let payload = {
        method,
        headers
    };

    if (method === 'POST' || method === 'PUT') {
        payload.body = JSON.stringify(inputObj);
    }

    try {
        const response = await fetch(BASE_URL + endPoint, payload);
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        if(!returnJSON){
            return response;
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.log(error);
    }
};

export const getData = async (endPoint, returnJSON = true) => {
    return fetchData(endPoint, 'GET', null, returnJSON);
};

export const postDataForLogin = async (type, userData) => {
    const payload = {
        method: 'POST',
        headers: {
            "access-control-allow-origin": "*",
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
    };

    try {
        const response = await fetch(BASE_URL + type, payload);
        const result = await response.json();
        return result;
    } catch (error) {
        console.log(error);
    }
};

export const postData = async (endPoint, inputObj) => {
    return fetchData(endPoint, 'POST', inputObj);
};

export const postDataForUpload = async (endPoint, inputObj) => {
    const token = SessionManager.getToken();
    const payload = {
        method: 'POST',
        headers: {
            "access-control-allow-origin": "*",
            'Authorization': 'Bearer ' + token
        },
        body: inputObj
    };

    try {
        const response = await fetch(BASE_URL + endPoint, payload);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const deleteData = async (endPoint) => {
    return fetchData(endPoint, 'DELETE', null);
};

export const putData = async (endPoint, obj) => {
    return fetchData(endPoint, 'PUT', obj);
};

export const getDataFromFastAPI = async (endPoint, returnJSON = true) => {
    const payload = {
        method: 'GET',
        headers: {
            "access-control-allow-origin": "*",
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await fetch(FAST_API_BASE_URL + endPoint, payload);
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        if(!returnJSON){
            return response;
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.log(error);
    }
};
