import React, { useEffect, useState } from "react";
import { getData } from "../Services/AccessAPI";
import "./PopularTags.css";

const PopularTags = () => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const data = await getData("api/Tag/getPopularTags");
        if (data) {
          setTags(data.message);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchTags();
  }, []);

  return (
    <div>
      <h2 className="container-title">Popular Tags</h2>
      <table className="tag-table">
        <thead>
          <tr>
            <th>Tag Name</th>
            <th>Frequency</th>
          </tr>
        </thead>
        <tbody>
          {tags.map((tag, index) => (
            <tr key={index}>
              <td>{tag.tagLabel}</td>
              <td>{tag.tagFrequency}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PopularTags;
